import Header from "./components/Header";
import Home from "./Home";
import {BrowserRouter as Router,Route,Routes} from "react-router-dom";
import Books from "./Books";
import Projects from "./Projects";
import Resources from "./Resources";
import Contact from "./Contact";


function App() {
  return (
    <>
      <Router>
        <Header />
        <div className="w-full h-full px-10 m-0 my-20 font-sans lg:px-96">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path='/books' element={<Books />} />
            <Route path='/projects' element={<Projects />} />
            <Route path='/resources' element={<Resources />} />
            <Route path='/contact' element={<Contact />} />
          </Routes>
        </div>
      </Router>
    </>
  );
}

export default App;
