import React from 'react'
import headshot from './assets/headshot-2.png'
import contactFile from './assets/contact.vcf'

export default function Contact() {
    return (
        <div className='flex flex-col items-start justify-center w-full h-full'>
            <img src={headshot} className='w-1/2 rounded-full lg:w-1/6' alt='Headshot' />
            <h1 className='w-full mt-6 text-4xl font-bold'>Hi, I'm Apostolos</h1>
            <p className='text-xl leading-loose list-decimal font-extralight'><a className='text-primary-700 hover:text-primary-800' href={contactFile}>Save as a contact</a></p>
            <h2 className='mt-12 mb-6 text-3xl font-semibold'>What I Do</h2>
            <ol type='1' className='ml-5 text-xl leading-loose list-decimal font-extralight'>
                <li>I study Economics, Management, and Computer Science in Milan, Italy</li>
                <li>I started an <a className='text-primary-700 hover:text-primary-800' href='https://www.enwrite.co'>AI startup</a> before it was trendy</li>
                <li>I help startups get VC-funded through <a className='text-primary-700 hover:text-primary-800' href='https://www.vcos.app'>VC_OS</a></li>
                <li>I post useful business insights on my <a className='text-primary-700 hover:text-primary-800' href='https://linkedin.com/in/dedeloudis'>Linkedin</a></li>
            </ol>
            <h2 className='mt-12 mb-6 text-3xl font-semibold'>Contact Info</h2>
            <ol className='ml-5 text-xl leading-loose list-decimal font-extralight'>
                <li>Feel free to reach out to me on <a className='text-primary-700 hover:text-primary-800' href='tel:+306949859896'>+30 6949859896</a> and <a className='text-primary-700 hover:text-primary-800' href='tel:+393444616855'>+39 3444616855</a></li>
                <li>Or send me an email at <a className='text-primary-700 hover:text-primary-800' href='mailto:apostolos@apostolosdedeloudis.com'>apostolos@apostolosdedeloudis.com</a></li>
            </ol>
            <h2 className='mt-12 mb-6 text-3xl font-semibold'>Socials</h2>
            <ol className='ml-5 text-xl leading-loose list-decimal font-extralight'>
                <li><a className='text-primary-700 hover:text-primary-800' href='https://instagram.com/apostolosdedeloudis'>Instagram</a></li>
                <li><a className='text-primary-700 hover:text-primary-800' href='https://twitter.com/apostolosded'>Twitter</a></li>
                <li><a className='text-primary-700 hover:text-primary-800' href='https://linkedin.com/in/dedeloudis'>Linkedin</a></li>
            </ol>
        </div>
    )
}
