import React from 'react'

export default function Projects() {
    return (
        <div>
            <h1 className='text-3xl font-bold'>Projects</h1>
            <h2 className='text-xl font-normal'>Things I've worked on</h2>
            <div className='grid grid-cols-1 gap-3 mt-5 md:grid-cols-2 lg:grid-cols-4'>
                <div className='flex flex-col items-center justify-center p-4 border border-gray-300 rounded-lg'>
                    <h3 className='text-xl font-bold'>Enwrite</h3>
                </div>
            </div>
        </div>
    )
}
