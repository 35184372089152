import React from 'react'
import {Link} from 'react-router-dom'
import monogram from '../assets/monogram.svg'

export default function Header() {
    // const [isOpen,setIsOpen]=React.useState(false)

    return (
        <nav className='relative flex items-center justify-between w-full px-10 py-8 shadow-lg h-fit'>
            <Link to='/' className='text-2xl font-bold'><img className='w-16 h-16 lg:w-20 lg:h-20' src={monogram} alt='Initials' /></Link>
            {/* <ul className='items-center hidden gap-5 text-lg justify-evenly lg:flex'>
                <li><Link className='transition-colors hover:text-primary' to='/'>Home</Link></li>
                <li><Link className='transition-colors hover:text-primary' to='/projects'>Projects</Link></li>
                <li><Link className='transition-colors hover:text-primary' to='/books'>Books</Link></li>
                <li><Link to='/resources' className='transition-colors hover:text-primary'>Resources</Link></li>
            </ul>
            <button className='flex items-center justify-center w-12 h-12 p-2 text-2xl text-black rounded-lg lg:hidden' onClick={() => setIsOpen(!isOpen)}>{isOpen? <XMarkIcon />:<Bars3Icon />}</button>
            <ul className={`items-center justify-evenly absolute top-full right-0 left-0 w-full h-fit gap-5 py-4 bg-primary lg:hidden flex-col text-2xl text-white font-bold ${isOpen? 'flex':'hidden'}`}>
                <li><Link onClick={() => setIsOpen(!isOpen)} className='transition-colors hover:text-primary-200' to='/'>Home</Link></li>
                <li><Link onClick={() => setIsOpen(!isOpen)} className='transition-colors hover:text-primary-200' to='/projects'>Projects</Link></li>
                <li><Link onClick={() => setIsOpen(!isOpen)} className='transition-colors hover:text-primary-200' to='/books'>Books</Link></li>
                <li><Link onClick={() => setIsOpen(!isOpen)} to='/resources' className='transition-colors hover:text-primary-200'>Resources</Link></li>
            </ul> */}

        </nav>
    )
}
